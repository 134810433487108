import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.resultCount > 0)?_c('div',[_c('h2',{staticClass:"pt-8"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"results-count"},[_vm._v(" Showing "),_c('strong',[_vm._v(_vm._s(_vm.resultCount))]),_vm._v(" Partner"),(_vm.resultCount != 1)?_c('span',[_vm._v("s")]):_vm._e()]),_c('div',{staticClass:"gepi-items-container"},_vm._l((_vm.sortedPartners),function(partner){return _c(VCard,{key:partner.id,staticClass:"content-panel box-third project-panel box-org partner-card",staticStyle:{"display":"inline-block"}},[_c('a',{staticClass:"no-underline",attrs:{"href":("/contacts/organizations/" + (partner.id))}},[_c(VCardTitle,{staticClass:"panel-title justify-center text-h6 link-card-title white--text",attrs:{"color":"#FFFFFF"}},[_c('div',{staticClass:"d-inline-flex flex-column"},[_c('span',{staticClass:"mb-n2"},[_vm._v(_vm._s(partner.name))]),(
                partner.grant_relationship &&
                  partner.grant_relationship === 'FUND'
              )?_c('span',{staticClass:"text-overline d-flex align-center"},[_c(VIcon,{staticClass:"mr-1",attrs:{"color":"yellow","x-small":""}},[_vm._v("mdi-star")]),_vm._v("FUNDER")],1):_vm._e()])]),_c(VCardText,{staticClass:"grey--text pt-4 font-weight-medium"},[_vm._v(" "+_vm._s(partner.description)+" "),_c(VCardText,{staticClass:"pt-5 mb-n6"},[_c(VChip,{staticClass:"font-weight-medium ml-n4",attrs:{"small":"","color":"var(--very-light-grey)","text-color":"var(--very-dark-grey)"}},[_vm._v(" "+_vm._s(partner.type[1])+" ")])],1)],1)],1)])}),1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }