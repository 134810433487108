<template>
  <div>
    <div
      class="search-options"
      v-if="searchFilter || orgFilter || activeProgFilter"
    >
      <div class="box-container">
        <div class="box-half" v-if="searchFilter">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              class="ml-n6"
            ></v-text-field>
          </v-card-title>
        </div>
        <div class="box-half" v-if="orgFilter || activeProgFilter">
          <v-card-text class="org-autocomplete" v-if="orgFilter">
            <v-autocomplete
              v-model="organization"
              :items="organizations"
              :loading="orgsAreLoading"
              :search-input.sync="searchOrganizations"
              label="Organizations"
              placeholder="Start typing to Search"
              prepend-icon="mdi-database-search"
              hide-no-data
              hide-details
              class="ml-16 mt-n4"
            ></v-autocomplete>
          </v-card-text>
          <v-switch
            v-if="activeProgFilter"
            class="pl-16 mt-0 mb-0 ml-12"
            v-model="onlyActivePrograms"
            label="Has active programs"
            inset
          ></v-switch>
        </div>
      </div>
    </div>
    <div class="results-count mt-n8">
      Showing <strong>{{ count }}</strong> <span v-if="count == 1"> Person</span
      ><span v-else> People</span>
    </div>
    <v-card>
      <v-data-table
        :headers="_headers"
        :items="people"
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
        multi-sort
        @pagination="getPageCount"
        show-select
        v-model="markedPeople"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <div
              class="d-flex align-center justify-space-between"
              style="width: 100%;"
            >
              <div class="flex-grow-1 mr-4" style="max-width: 80%;">
                <v-autocomplete
                  v-model="selectedPeople"
                  :items="transformedKeyContactOptions"
                  item-text="full_name"
                  item-value="id"
                  label="Select people"
                  return-object
                  multiple
                  class="mt-12"
                  :item-disabled="(item) => item.type === 'header'"
                  clearable
                  v-if="keyContactOptionsEndPoint"
                >
                  <template v-slot:item="{ item }">
                    <template v-if="item.type === 'header'">
                      <div class="subheader">{{ item.name }}</div>
                    </template>
                    <template v-else>
                      <v-list-item-content :key="item.id">
                        <v-list-item-title
                          v-html="item.full_name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </div>
              <v-btn
                class="text-none text-subtitle-1 mt-6"
                color="#17BEBB"
                variant="flat"
                @click="showAddDialog = true"
                :disabled="
                  (keyContactOptionsEndPoint && markedPeople.length > 0) ||
                    (keyContactOptionsEndPoint &&
                      selectedPeople.length === 0) ||
                    (!keyContactOptionsEndPoint && markedPeople.length === 0)
                "
                v-if="showAddContactsButton"
              >
                <span style="color: #fff">Add Key Contacts</span>
              </v-btn>

              <v-btn
                class="text-none text-subtitle-1 ml-2 mt-6"
                color="var(--red)"
                variant="flat"
                @click="showDeleteDialog = true"
                :disabled="markedPeople.length === 0"
                v-if="showDeleteContactsButton"
              >
                <span style="color: #fff">Remove Key Contacts</span>
              </v-btn>
            </div>
          </v-toolbar>
        </template>

        <template v-slot:item.full_name="{ item }">
          <a :href="`/contacts/people/${item.id}`">{{ item.full_name }}</a>
        </template>
        <template v-slot:item.job_title="{ item }">
          {{ item.job_title[item.job_title.length - 1] | formatNull }}
        </template>
        <template
          v-slot:item.organizations="{ item }"
          v-if="columns.includes('Organization')"
        >
          <td :class="getTableCellClasses(item.organizations)">
            <a
              v-if="
                item.organizations[item.organizations.length - 1]
                  .organization != 0
              "
              :href="
                `/contacts/organizations/${
                  item.organizations[item.organizations.length - 1].organization
                }`
              "
            >
              {{ item.organizations[item.organizations.length - 1].name }}</a
            >
            <span v-else>-</span>
          </td>
        </template>
        <template
          v-slot:item.organizations="{ item }"
          v-else-if="columns.includes('Department')"
        >
          {{
            item.organizations[item.organizations.length - 1].department
              | formatNull
          }}
        </template>
        <template v-slot:item.organizations="{ item }" v-else>
          <a
            :href="
              `mailto:${
                item.organizations[item.organizations.length - 1].email
              }`
            "
          >
            {{ item.organizations[item.organizations.length - 1].email }}</a
          >
        </template>
        <template
          v-slot:item.projects="{ item }"
          v-if="columns.includes('Project')"
        >
          <a
            :href="
              `/projects/${item.projects[item.projects.length - 1].project_id}`
            "
          >
            {{ item.projects[item.projects.length - 1].name }}</a
          >
        </template>
      </v-data-table>
      <delete-confirmation-dialog
        :title="'Remove Key Contacts Confirmation'"
        :dialog="showDeleteDialog"
        :message="generateRemoveKeyContactsConfirmationHtml()"
        :buttonText="'Remove'"
        @delete="deleteKeyContact"
        @cancel="showDeleteDialog = false"
      ></delete-confirmation-dialog>
      <v-dialog v-model="showAddDialog" max-width="600" scrollable>
        <v-card>
          <v-btn icon @click="showAddDialog = false" class="close-button">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card-title class="headline"
            >Add Key Contacts Confirmation</v-card-title
          >
          <v-card-text
            v-html="generateAddKeyContactsConfirmationHtml()"
          ></v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="createKeyContacts()">Add</v-btn>
            <v-btn text @click="showAddDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="snackbarVisible" :timeout="3000" color="#17BEBB">
        {{ confirmationMessage }}
      </v-snackbar>
    </v-card>
  </div>
</template>
<style>
/* Page CSS */
.input-box {
  width: 90%;
}
#search {
  position: relative;
  top: 18px;
}

.search-options {
  padding-bottom: 40px;
}

.v-application--is-ltr .v-input__prepend-outer {
  display: none;
}
.v-application .ml-16 {
  padding: 10px;
}

.org-autocomplete {
  padding-top: 30px;
}
.custom-toolbar-align-right {
  position: relative; /* Ensures that positioning is relative to the toolbar */
}

.custom-toolbar-align-right .v-btn {
  position: absolute;
  right: 16px; /* Aligns the button to the far right */
}

.close-button {
  position: absolute;
  top: 1px;
  right: 1px;
}

@media screen and (max-width: 1000px) {
  .v-application .ml-16 {
    margin-left: 0px !important;
  }

  .org-autocomplete {
    padding-left: 0px;
  }
}
.subheader {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.54);
  padding: 8px 0;
}
</style>
<script>
import pageCountMixin from "../mixins/pageCount";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog.vue";

export default {
  props: {
    endPoint: {
      type: String,
      required: false,
      default: "contacts/people?ordering=name&limit=1000",
    },
    keyContactOptionsEndPoint: {
      type: String,
      required: false,
    },
    columns: {
      type: Array,
      required: false,
      default: () => ["Name", "Job Title", "Organization", "Programs"],
    },
    searchFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    orgFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    activeProgFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    onlyActivePrograms: {
      type: Boolean,
      required: false,
      default: false,
    },
    projects: {
      type: Array,
      required: false,
      default: () => [],
    },
    showSelections: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAddContactsButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDeleteContactsButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DeleteConfirmationDialog,
  },
  mixins: [pageCountMixin],
  data() {
    return {
      people: [],
      organizations: [],
      selectedPeople: [],
      markedPeople: [],
      search: "",
      loading: true,
      orgsAreLoading: false,
      organization: "",
      queryTerm: "",
      keyContactOptions: [],
      showDeleteDialog: false,
      showAddDialog: false,
      snackbarVisible: false,
      confirmationMessage: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "full_name",
          cellClass: "table-item table-link",
        },
        {
          text: "Job Title",
          value: "job_title",
          sortable: true,
          cellClass: "table-item",
        },
        {
          text: "Organization",
          value: "organizations",
          sortable: true,
          sort: (a, b) => {
            let orgA = a[a.length - 1].name.toLowerCase();
            let orgB = b[b.length - 1].name.toLowerCase();
            if (orgA < orgB)
              //sort string ascending
              return -1;
            if (orgA > orgB) return 1;
            return 0; //default return value (no sorting)
          },
          cellClass: (item) => {
            return item.organizations && item.organizations.length > 0
              ? "table-item table-link"
              : "table-item";
          },
          filter: (value) => {
            if (!this.organization) return true;
            return value[value.length - 1].name === this.organization;
          },
        },
        {
          text: "Programs",
          value: "program_count",
          sortable: true,
          cellClass: "table-item",
          filter: (value) => {
            if (!this.onlyActivePrograms) return true;
            return value > 0;
          },
        },
        {
          text: "Email",
          value: "organizations",
          sort: (a, b) => {
            let orgA = a[a.length - 1].email.toLowerCase();
            let orgB = b[b.length - 1].email.toLowerCase();
            if (orgA < orgB)
              //sort string ascending
              return -1;
            if (orgA > orgB) return 1;
            return 0; //default return value (no sorting)
          },
          sortable: true,
          cellClass: "table-item",
        },
        {
          text: "Project",
          value: "projects",
          sortable: true,
          sort: (a, b) => {
            let projA = a[a.length - 1].name.toLowerCase();
            let projB = b[b.length - 1].name.toLowerCase();
            if (projA < projB)
              //sort string ascending
              return -1;
            if (projA > projB) return 1;
            return 0; //default return value (no sorting)
          },
          cellClass: "table-item table-link",
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  created() {
    this.getPeople();
    this.loadOrganizations();

    if (this.keyContactOptionsEndPoint) {
      this.getKeyContactOptions();
    }
  },

  computed: {
    resultCount: function() {
      return this.people.length;
    },
    _headers() {
      return this.headers.filter((item) => this.columns.includes(item.text));
    },
    searchOrganizations: {
      get() {
        return this.queryTerm;
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.loadOrganizations();
        }
      },
    },
    sortedKeyContactOptions() {
      const people = [...this.keyContactOptions];
      return people.sort((a, b) => {
        const orgNameA = a.organizations[0].name.toUpperCase();
        const orgNameB = b.organizations[0].name.toUpperCase();
        const fullNameA = a.full_name.toUpperCase();
        const fullNameB = b.full_name.toUpperCase();

        if (orgNameA < orgNameB) return -1;
        if (orgNameA > orgNameB) return 1;

        if (fullNameA < fullNameB) return -1;
        if (fullNameA > fullNameB) return 1;

        return 0;
      });
    },
    transformedKeyContactOptions() {
      let transformed = [];
      let currentOrg = "";
      let orgsWithPeople = [];

      this.sortedKeyContactOptions.forEach((person) => {
        const orgName = person.organizations[0].name;

        if (orgName !== currentOrg) {
          transformed.push({
            type: "header",
            name: orgName,
            id: person.organizations[0].id,
          });
          currentOrg = orgName;
          orgsWithPeople.push(orgName);
        }

        transformed.push({
          type: "person",
          ...person,
        });
      });

      // Filter out organizations without associated people
      transformed = transformed.filter((item) => {
        if (item.type === "header") {
          return orgsWithPeople.includes(item.name);
        }
        return true;
      });

      return transformed;
    },
  },
  watch: {
    people(currentValue) {
      this.$emit("people", currentValue);
    },
    beforeMount() {
      this.$emit("people", this.people);
    },
  },
  methods: {
    getPeople: async function() {
      this.people = [];
      let url = this.endPoint;
      while (url) {
        let response = await this.$api.get(url);
        await this.people.push(...response.data.results);
        url = response.data.next;
      }
      this.loading = false;
    },

    getKeyContactOptions: async function() {
      let url = this.keyContactOptionsEndPoint;
      while (url) {
        let response = await this.$api.get(url);
        await this.keyContactOptions.push(...response.data.results);
        url = response.data.next;
      }
    },

    queryOrganizations: function(search) {
      return new Promise((resolve, reject) => {
        this.$api
          .get(`contacts/organizations?ordering=name&search=${search}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async loadOrganizations() {
      this.organizations = await this.queryOrganizations(this.queryTerm || "");
      this.organizations = this.organizations.data.results.map((o) => o.name);
      this.orgsAreLoading = false;
    },

    getTableCellClasses(org) {
      return {
        "table-item": true,
        "table-link":
          org && org.length > 0 && org[org.length - 1].organization != 0,
      };
    },

    createKeyContacts() {
      const contacts = this.keyContactOptionsEndPoint
        ? this.selectedPeople
        : this.markedPeople;
      const contactsToCreate = contacts.flatMap((person) =>
        this.projects.map((project) => ({
          person: person.id,
          project: project.project_id,
          role: "KC",
        }))
      );

      this.$api
        .post("projects/role/bulk_create", contactsToCreate)
        .then((response) => {
          console.log("Bulk contact creation successful:", response);
          this.getPeople();
          if (!this.keyContactOptionsEndPoint) {
            this.$emit("update-key-contacts");
          }
          this.setConfirmationMessage("Key Contacts created successfully!");
        })
        .catch((error) => {
          console.error("Error during bulk contact creation:", error);
        })
        .finally(() => {
          this.selectedPeople = [];
          this.markedPeople = [];
          this.showAddDialog = false;
        });
    },

    listKeyContactNames() {
      const contacts =
        this.keyContactOptionsEndPoint && !this.markedPeople.length
          ? this.selectedPeople
          : this.markedPeople;

      // Create a sorted copy of contacts array
      const sortedContacts = contacts
        .slice()
        .sort((a, b) => a.full_name.localeCompare(b.full_name));

      return sortedContacts
        .map((person) => `<li>${person.full_name}</li>`)
        .join("");
    },

    deleteKeyContact() {
      const allDeletedRoles = this.markedPeople.flatMap((person) =>
        person.projects
          .filter((project) =>
            this.projects.some(
              (proj) =>
                proj.project_id === project.project_id &&
                project.role_code === "KC"
            )
          )
          .map((project) => project.id)
      );
      this.$api
        .post("projects/role/bulk_delete", allDeletedRoles)
        .then((response) => {
          console.log("Bulk contact deletion successful:", response);
          this.getPeople();
          this.setConfirmationMessage("Key Contacts removed successfully!");
        })
        .catch((error) => {
          console.error("Error during bulk contact deletion:", error);
        })
        .finally(() => {
          this.showDeleteDialog = false;
          this.markedPeople = [];
        });
    },

    setConfirmationMessage(message) {
      this.snackbarVisible = true;
      this.confirmationMessage = message;
    },

    generateAddKeyContactsConfirmationHtml() {
      const projectNames = this.projects
        .map((project) => project.name)
        .sort((a, b) => a.localeCompare(b))
        .map((name) => `<li>${name}</li>`)
        .join("");

      return `<p>You are about to add the following people as key contacts:</p>
        <p><strong>${this.listKeyContactNames()}</strong></p>
        <p>Linking them to the following project(s):</p>
        <p><strong>${projectNames}</strong></p>`;
    },

    generateRemoveKeyContactsConfirmationHtml() {
      const projectNames = new Set();
      this.markedPeople.forEach((person) => {
        person.projects.forEach((project) => {
          if (
            this.projects.some(
              (proj) =>
                proj.project_id === project.project_id &&
                project.role_code === "KC"
            )
          ) {
            projectNames.add(project.name);
          }
        });
      });

      const uniqueProjectNames = [...projectNames]
        .sort((a, b) => a.localeCompare(b))
        .map((name) => `<li>${name}</li>`)
        .join("");

      return `<p>You are about to remove the following key contact(s) as key contacts:</p>
        <p><strong>${this.listKeyContactNames()}</strong></p>
        <p>From the following project(s):</p>
        <p><strong>${uniqueProjectNames}</strong></p>
        <p>This action will <em>unlink</em> the contacts from the specified projects but will not delete the contact records.</p>`;
    },
  },
};
</script>
