export const refreshTriggerMixin = {
  data() {
    return {
      refreshTrigger: 0,
    };
  },
  methods: {
    refresh() {
      this.refreshTrigger++; // Update the trigger to refresh the component
    },
  },
};
export default refreshTriggerMixin;
